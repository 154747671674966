@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
    @include mat.icon-button-overrides((
       icon-size: 18px
    ));

    @include mat.option-overrides((
        label-text-size: 14px
    ));
}

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

.black-06 {
    color: rgba(0, 0, 0, 0.6);
}

.black-08 {
    color: rgba(0, 0, 0, 0.8);
}

.white-06 {
    color: rgba(255, 255, 255, 0.6);
}

.white-08 {
    color: rgba(255, 255, 255, 0.8);
}


.small {
    font-size: x-small;
}

.all-data-tooltip {
    word-break: break-all;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-50 {
    margin-right: 50px;
}


.margin-right-10 {
    margin-right: 10px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}


.color-accent {
    color: #ff4081;
}

.color-warning {
    color: #ff4d4d;
}

.color-green {
    color: #57b50c;
}

.smaller {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 36px;
    }
}

.fullwidth {
    width: 100%;
}

.flex {
    display: flex;
    align-items: center;

    .flex-1 {
        flex: 1;
    }
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}


.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.message-bar {
    background-color: #3A3B4E;
}

.max-width-200 {
    max-width: 200px;
}

.max-width-300 {
    max-width: 300px;
}

.decoration-title-1 {
    font-size: 22px;
    font-weight: 600;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    color: #3A3B4E;

    margin-bottom: 5px;

    white-space: nowrap;
}

.itallic {
    font-style: italic;
}

.padding-50 {
    padding: 50px;
}

.padding-20 {
    padding: 20px;
}


@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }

    .flex-mobile-column {
        flex-direction: column;
    }

    .mobile-margin-bottom-0 {
        margin-bottom: 0px;
    }

    .mobile-margin-bottom-30 {
        margin-bottom: 30px;
    }
}