/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

.black-06 {
    color: rgba(0, 0, 0, 0.6);
}

.black-08 {
    color: rgba(0, 0, 0, 0.8);
}

.white-06 {
    color: rgba(255, 255, 255, 0.6);
}

.white-08 {
    color: rgba(255, 255, 255, 0.8);
}


.small {
    font-size: x-small;
}

.all-data-tooltip {
    word-break: break-all;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.color-accent {
    color: #ff4081;
}

.color-warning {
    color: #ff4d4d;
}

.color-green {
    color: #57b50c;
}

.smaller {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 36px;
    }
}

.fullwidth {
    width: 100%;
}

.flex {
    display: flex;
    align-items: center;

    .flex-1 {
        flex: 1;
    }
}

.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}